<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            v-model="carroceria.descricao"
            :rules="[
              () => !!carroceria.descricao || 'Esse campo é obrigatório!',
            ]"
            ref="descricao"
            label="Descrição"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'CadastroCarroceria',
  props: {
    carroceria: Object,
    formTitle: String,
    carroceriaIndex: Number,
  },

  data() {
    return {
      formError: false,
      defaultItem: {
        descricao: '',
      },
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    form() {
      return {
        descricao: this.carroceria.descricao,
      }
    },
  },

  methods: {
    ...mapActions('patio', [
      'addCarroceria',
      'editCarroceria',
      'deleteCarroceria',
    ]),

    close() {
      this.formError = false
      setTimeout(() => {
        Object.assign(this.carroceria, this.defaultItem)
        this.resetarFormulario()
      }, 300)

      this.$emit('close')
    },

    resetarFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },

    verificaFormulario() {
      this.formError = false

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formError = true

        this.$refs[f].validate(!this.form[f])
      })
    },

    save() {
      this.verificaFormulario()
      if (this.formError) return

      if (this.carroceriaIndex > -1) {
        this.editCarroceria(this.carroceria)
      } else {
        // this.carroceria.proprietario = this.empresaAtual.public_id
        this.addCarroceria(this.carroceria)
      }

      this.close()
    },
  },
}
</script>
<style>
</style>